import './Styles/Pages.css'

import Handbook from './Handbook';

function App() {
  return (
    <div>
      <Handbook />
    </div>
  );
}

export default App;
