import { TOCnode } from "./Base";

export const BOTTOM_CHILDREN =
[
    // {
    //     id: 'bottom-turtle',
    //     label: 'Turtle',
    //     children: [

    //     ]
    // },
    // {
    //     id: 'bottom-guard',
    //     label: 'Guard',
    //     children: [
            
    //     ]
    // },
    // {
    //     id: 'bottom-scoringpositions',
    //     label: 'Pins/Scoring Positions',
    //     children: [
            
    //     ]
    // },
]