import { TOCnode } from './Base';

export const LEGGRABS_CHILDREN: TOCnode[] =
[
    {
        id: 'singleleg',
        label: 'Single Leg',
        children: [],
        icons: ['LibraryBooks', 'OndemandVideo']
    },
    // {
    //     id: 'doubleleg',
    //     label: 'Double Leg',
    //     children: [],
    // },
    // {
    //     id: 'highcrotch',
    //     label: 'High Crotch',
    //     children: [],
    // },
    // {
    //     id: 'leggrabs-other',
    //     label: 'Other',
    //     children: [],
    // },
]