import { TOCnode } from './Base';

export const UPPERBODY_CHILDREN: TOCnode[] =
[
    {
        id: 'underhook',
        label: 'Underhook',
        children: [],
        icons: ['LibraryBooks', 'OndemandVideo']
    },
]