export const TOP_CHILDREN =
[
    // {
    //     id: 'top-turtle',
    //     label: 'Turtle',
    //     children: [

    //     ],
    //     icons: ['OndemandVideo']
    // },
    // {
    //     id: 'top-guard',
    //     label: 'Guard',
    //     children: [
            
    //     ]
    // },
    // {
    //     id: 'top-scoringpositions',
    //     label: 'Pins/Scoring Positions',
    //     children: [
            
    //     ]
    // },
]